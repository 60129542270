<template>
   <div>
        <v-card
            outlined
            class="modern-card"
            :style="computedStyle(id)"
            @click.stop="copyToClipboard"
        >
            <div
                v-html="value"
                style="white-space: pre-line;"></div>
            <v-icon
                v-if="copiedId === id"
                class="copy-feedback-icon"
            >mdi-clipboard-check</v-icon>
        </v-card>
    </div>
</template>
<script>
export default {
  props: {
    copiedId: {
      required: false,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    for: {
      required: true,
      type: String,
    },
    template: {
      required: true,
      type: Object,
    },
    locale: {
      required: true,
      type: String,
    },
  },
  computed: {
    id() {
      return `${this.type}.${this.for}`;
    },
    value() {
      return this.template[this.locale][this.type][this.for];
    },
    computedStyle() {
      return (id) => {
        if (this.copiedId === id) {
          return 'copied';
        }
        return '';
      };
    },
  },
  methods: {
    async copyToClipboard() {
      try {
        this.$emit('copiedId', this.id);
        await navigator.clipboard.writeText(this.value);
        this.$store.dispatch('message', `${this.template.name} (${this.id}) Copied`);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },
  },
};
</script>
<style scoped>
.pre-style {
    white-space: pre-line;
    margin: 0;
    font-family: inherit;
    padding: 1rem; /* Add some padding for better visual appeal */
}

.modern-card {
    border:1px solid black;
    padding: 10px;
    transition: all 0.3s ease; /* Soft transition effect */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation */
}

.modern-card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Darker shadow on hover for elevation effect */
    transform: translateY(-3px); /* Slight upward lift on hover */
}

.modern-card.copied {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px) scale(1.02);
    animation: pulse 5.5s infinite;
}

.copy-feedback-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #4CAF50;
    animation: fadeOut 2.5s forwards;
}

@keyframes pulse {
    0% {
        transform: translateY(-5px) scale(1.02);
    }
    50% {
        transform: translateY(-5px) scale(1.05);
    }
    100% {
        transform: translateY(-5px) scale(1.02);
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}
</style>
