<template>
  <modal
      :show="show"
      :title="`Da li si siguran da hoces da pošalješ email?`"
      @save="save"
      saveText="Pošalji"
      @close="close"
      :sticky-header="true"
  >
      <v-container  class="px-5">
        <h3>Da li ste sigurni da želite da pošaljete vlasniku i gostu email da je uplaćen avans i da je potrvdjena rezervacija?</h3>
        <p>
          Kada kliknete dugme pošalji, email će biti poslat na queue i biće dostavljen vlasniku i gostu. ( mozda nece biti dostavljen odmah)
          <br/>
          <br/>
          Nemojte da klikćete više puta, (svaki put kad kliknete će poslati email)
        </p>
      </v-container>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    reservationId: {
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    save() {
      if (this.loading) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/reservations/${this.reservationId}/send-advance-payment-paid-email`)
        .then(() => {
          this.$store.dispatch('message', 'Email send to Queue');
          this.close();
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('message', 'Email NIJE POSLAT');
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.isSaveButtonDisabled = false;
        });
    },
    close() {
      // this.show = false;
      this.$emit('close');
    },
  },
};
</script>
