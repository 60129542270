<template>
  <main-layout :title="$t('Delete your account')" :infos="infos">
    <template v-slot:body>
      <v-card outlined rounded class="pa-10">
        <v-card-text class="pa-0">
          <h2>{{$t('Delete your account from Bookaweb')}}</h2>
          <p class="text-body-1 font-weight-medium my-6">
              {{$t('delete_account_page_subtitle')}}
          </p>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn color="red white--text" @click="showDeleteAccountModal = true">
            {{$t('Delete your account')}}
          </v-btn>
        </v-card-actions>
      </v-card>

      <modal
        :show="showDeleteAccountModal"
        @close="showDeleteAccountModal = false"
        @save="confirmDeleteAccountModal"
        save-text="Submit"
        :title="$t('delete_account_modal_title')"
        :description="$t('delete_account_modal_description')"
        hide-top-close-button
        max-width="400"
      />

      <modal
        :show="showConfirmDeleteAccountModal"
        @close="redirectToDashboard"
        @save="redirectToDashboard"
        save-text="OK"
        :title="$t('delete_account_confirm_modal_title')"
        :description="$t('delete_account_confirm_modal_description')"
        hide-top-close-button
        max-width="400"
        :hideActionsCancel="true"
      />

    </template>
  </main-layout>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      infos: [
        {
          title: 'Infos.DeleteAccount.title',
          description: 'Infos.DeleteAccount.description',
          icon: 'green-energy',
        },
      ],
      showDeleteAccountModal: false,
      showConfirmDeleteAccountModal: false,
    };
  },
  methods: {
    confirmDeleteAccountModal() {
      this.$store.dispatch('loading', true);
      axios
        .post('/email/request-delete-account')
        .then(() => {
          this.$store.dispatch('message', this.$t('Account deletion request sent'));
          this.$store.dispatch('loading', false);
          this.showDeleteAccountModal = false;
          this.showConfirmDeleteAccountModal = true;
        })
        .catch((data) => {
          this.$store.dispatch('setErrors', { message: [data.response.data.message] });
          this.$store.dispatch('loading', false);
        });
    },
    redirectToDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>
