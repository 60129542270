<template>
    <main-layout :title="$t('Government ID')" :infos="infos">
        <template v-slot:body>
            <v-card outlined rounded :loading="loading">
                <v-card-text class="pa-10">
                    <h2>{{$t('Upload pictures of your ID card')}}</h2>
                    <p class="grey--text text-body-1 text--grey darken-1 mb-6 mt-2">
                        {{$t('Your face and unique identification number must be visible in the pictures')}}
                    </p>
                    <div
                      class="d-flex flex-column flex-md-row justify-center text-center"
                      style="gap: 35px;"
                    >
                        <div
                          v-if="front"
                          class="d-flex flex-column align-center"
                        >
                            <h3 class="black--text">{{$t('Upload front')}}</h3>
                            <p class="mb-3">({{$t('JPG or PNG only')}})</p>
                            <div v-if="isGovermentIdVerified">
                                <a :href="Object.values(front)[0].original_url" target="_blank">
                                  <img :src="Object.values(front)[0].original_url" class="admin-preview" alt="front">
                                </a>
                            </div>
                            <media-library-attachment
                                v-else
                                :key="Object.keys(front)[0]"
                                class="attachment-government attachment-government-front mb-7 "
                                name="attachment"
                                @change="onChangeFront"
                                :after-upload="submitFront"
                                :initial-value="front"
                                :validation-errors="attachmentErrors"
                                :validation-rules="{
                                accept: ['image/png', 'image/jpeg'],
                                maxSizeInKB: 1024*5,
                                minSizeInKB: 10
                                }"
                                :upload-domain="uploadDomain"
                                :translations="{ ...translations, selectOrDragMax: $t('Upload Government ID') }"
                            />
                        </div>
                        <div
                          v-if="back"
                          class="d-flex flex-column align-center"
                        >
                            <h3 class="black--text">{{$t('Upload back')}}</h3>
                            <p class="mb-3">({{$t('JPG or PNG only')}})</p>
                            <div v-if="isGovermentIdVerified">
                                <a :href="Object.values(back)[0].original_url" target="_blank">
                                  <img :src="Object.values(back)[0].original_url" class="admin-preview" alt="back">
                                </a>
                            </div>
                            <media-library-attachment
                                v-else
                                :key="Object.keys(back)[0]"
                                class="attachment-government attachment-government-back mb-7 "
                                name="attachment"
                                @change="onChangeBack"
                                :after-upload="submitBack"
                                :initial-value="back"
                                :validation-errors="attachmentErrors"
                                :validation-rules="{
                                accept: ['image/png', 'image/jpeg'],
                                maxSizeInKB: 1024*5,
                                minSizeInKB: 10
                                }"
                                :upload-domain="uploadDomain"
                                :translations="{ ...translations, selectOrDragMax: $t('Upload Government ID') }"
                            />
                        </div>

                    </div>
                    <div v-if="isGovermentIdVerified" class="flex text-center mt-10">
                      <v-icon color="green">mdi-check-circle</v-icon>
                      {{ $t('Your government ID has been verified') }}!
                    </div>
                </v-card-text>
                <v-divider/>
                <v-card-actions class="px-10 py-4">
                    <!-- <v-spacer></v-spacer> -->
                    <v-btn color="primary" @click.stop="$router.go(-1)">
                        {{ $t("Back") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </main-layout>
</template>
<style scoped>
.admin-preview{
  max-width:100%;
  margin:0 auto;
}
</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import ViewAsAdminMixin from '@/lib/ViewAsAdminMixin';
import MediaLibraryMixin from '@/lib/MediaLibraryMixin';

export default {
  mixins: [ViewAsAdminMixin, MediaLibraryMixin],
  data() {
    return {
      infos: [
        {
          title: 'Infos.AccountSettings.title',
          description: 'Infos.AccountSettings.description',
          icon: 'green-energy',
          youtube: 'https://www.youtube.com/embed/WM8lbSfU0dU',
        },
      ],
      frontAttachment: undefined,
      backAttachment: undefined,
      front: undefined,
      back: undefined,
      attachmentErrors: {},
      user: undefined,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    isGovermentIdVerified() {
      if (!this.user) {
        return false;
      }
      return Boolean(this.user.government_id_verified_at !== null);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    onChangeFront(media) {
      this.frontAttachment = media;
    },
    onChangeBack(media) {
      this.backAttachment = media;
    },
    // submit() {
    //   console.log('submit');
    // },
    uploadAttachment(collection, attachment) {
      this.$store.dispatch('loading', true);
      axios
        .post(`/user-settings/media/attachment/${collection}`, {
          media: attachment,
        })
        .then(() => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Government ID photo changed'));
        })
        .catch((error) => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
    submitFront() {
      this.uploadAttachment('government_id_front', this.frontAttachment);
    },
    submitBack() {
      this.uploadAttachment('government_id_back', this.backAttachment);
    },
    load() {
      this.$store.dispatch('loading', true);
      axios
        .get(`user-settings/account-settings${this.viewAsAdmin ? `?viewAsAdmin=${this.viewAsAdmin}` : ''}`)
        .then(({ data }) => {
          if (data) {
            this.front = data.media_government_id.front;
            this.back = data.media_government_id.back;
            this.user = data;
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
  },
};
</script>
