export default {
  mounted() {
    document.body.style.filter = 'invert(100%)';
    document.body.classList.add('hide-hubspot-chat');
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
    document.body.style.filter = '';
    document.body.classList.remove('hide-hubspot-chat');
  },
  data() {
    return {
      refreshTimer: null,
    };
  },
  methods: {
    setAutoRefreshInterval(callback) {
      this.refreshTimer = setInterval(() => {
        callback();
      }, 1 * 60 * 1000);
    },
  },
};
