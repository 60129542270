<template>
  <main-layout
    :containerFluid="true"
    :hide-alerts="true"
    title="">
      <template v-slot:body>
        <div  class="flex-container">
          <div
            v-if="tv && tv.gost_zeli_da_rezervise"
            class="flex-item" style="border-right:1px solid black; border-bottom:1px solid black">
            <h2>Gost želi da rezerviše ({{ tv.gost_zeli_da_rezervise.length }})</h2>
            <inquries-in-table
              :inquries="tv.gost_zeli_da_rezervise"/>
          </div>
          <div
            v-if="tv && tv.advance_payment_expired"
            class="flex-item" style="border-bottom:1px solid black">
            <h2>Advance Payment Expired ({{ tv.advance_payment_expired.length }})</h2>
            <reservations-in-table
              :reservations="tv.advance_payment_expired"/>
          </div>
      </div>
      <reservation-preview-modal/>
      <inqury-preview-modal @reload="load"/>
    </template>
  </main-layout>
</template>
<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  overflow: hidden;
}

.flex-item {
  min-width: 50%;
  max-width: 50%;
  width:50%;
  overflow-y: auto;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}
</style>
<script>
import axios from 'axios';
import InquriesInTable from '@/components/inquries/InquriesInTable.vue';
import InquryPreviewModal from '@/components/inquries/InquryPreviewModal.vue';
import ReservationPreviewModal from '@/components/reservations/ReservationPreviewModal.vue';
import ReservationsInTable from '@/components/reservations/ReservationsInTable.vue';
import TvHelpersMixin from '@/lib/TvHelpers.mixin';

export default {
  mixins: [TvHelpersMixin],
  components: {
    InquryPreviewModal,
    InquriesInTable,
    ReservationPreviewModal,
    ReservationsInTable,
  },
  data() {
    return {
      tv: undefined,
    };
  },
  mounted() {
    this.$title = 'TV';
    this.load();

    this.setAutoRefreshInterval(this.load);
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      axios
        .get('/calendar/tv/double')
        .then(({ data }) => {
          this.tv = data;
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.log('ERror 23: ', error);
          // this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
  },
};
</script>
