import moment from 'moment';

export default {
  methods: {
    getPriceForDate(date) {
      const isWeekend = moment(date).isoWeekday() === 5 || moment(date).isoWeekday() === 6;
      const price = isWeekend
        ? this.specialPricing.price_weekend
        : this.specialPricing.price;

      return this.specialPricing[date]
        ? this.specialPricing[date]
        : price;
    },
    getPriceStringForDate(date) {
      const price = this.getPriceForDate(date);
      return `€ ${price}`;
    },
    getMinStayForDate(date) {
      if (this.calendarRulesDates[date] && this.calendarRulesDates[date].min_stay) {
        return this.calendarRulesDates[date].min_stay;
      }
      if (moment(date).isoWeekday() === 1 && this.apartment.min_stay.min_stay_monday) {
        return this.apartment.min_stay.min_stay_monday;
      }
      if (moment(date).isoWeekday() === 2 && this.apartment.min_stay.min_stay_tuesday) {
        return this.apartment.min_stay.min_stay_tuesday;
      }
      if (moment(date).isoWeekday() === 3 && this.apartment.min_stay.min_stay_wednesday) {
        return this.apartment.min_stay.min_stay_wednesday;
      }
      if (moment(date).isoWeekday() === 4 && this.apartment.min_stay.min_stay_thursday) {
        return this.apartment.min_stay.min_stay_thursday;
      }
      if (moment(date).isoWeekday() === 5 && this.apartment.min_stay.min_stay_friday) {
        return this.apartment.min_stay.min_stay_friday;
      }
      if (moment(date).isoWeekday() === 6 && this.apartment.min_stay.min_stay_saturday) {
        return this.apartment.min_stay.min_stay_saturday;
      }
      if (moment(date).isoWeekday() === 7 && this.apartment.min_stay.min_stay_sunday) {
        return this.apartment.min_stay.min_stay_sunday;
      }

      return this.apartment.min_stay.base;
    },
    getRuleColorForDate(date) {
      if (this.calendarRulesDates[date] && this.calendarRulesDates[date].color) {
        return this.calendarRulesDates[date].color;
      }
      return '#cccccc';
    },
    getPriceAdjustmentForDate(date) {
      if (this.calendarRulesDates[date] && this.calendarRulesDates[date].price_adjustment) {
        return this.calendarRulesDates[date].price_adjustment;
      }
      return null;
    },
    getPriceAdjustmentStringForDate(priceAdjustment) {
      if (Number(priceAdjustment) < 0) {
        return `${priceAdjustment}%`;
      }
      return `+${priceAdjustment}%`;
    },
    ruleString(minStay, priceAdjustment) {
      if (priceAdjustment) {
        if (priceAdjustment > 0) {
          return `min ${minStay} +${priceAdjustment}%`;
        }
        return `min ${minStay} ${priceAdjustment}%`;
      }
      return `min ${minStay}`;
    },
    getAdvancePaymentStatusIcon(reservation) {
      let icon;
      if (reservation.advance_payment && !reservation.advance_payment_paid) {
        icon = '<div class="avans-not-paid-icon"></div>';
      } else if (reservation.advance_payment && reservation.advance_payment_paid) {
        icon = '<div class="avans-paid-icon"></div>';
      } else {
        icon = '';
      }

      return icon;
    },
  },
};
