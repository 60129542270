var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"title":_vm.$t('Government ID'),"infos":_vm.infos},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-card',{attrs:{"outlined":"","rounded":"","loading":_vm.loading}},[_c('v-card-text',{staticClass:"pa-10"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Upload pictures of your ID card')))]),_c('p',{staticClass:"grey--text text-body-1 text--grey darken-1 mb-6 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('Your face and unique identification number must be visible in the pictures'))+" ")]),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-center text-center",staticStyle:{"gap":"35px"}},[(_vm.front)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h3',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t('Upload front')))]),_c('p',{staticClass:"mb-3"},[_vm._v("("+_vm._s(_vm.$t('JPG or PNG only'))+")")]),(_vm.isGovermentIdVerified)?_c('div',[_c('a',{attrs:{"href":Object.values(_vm.front)[0].original_url,"target":"_blank"}},[_c('img',{staticClass:"admin-preview",attrs:{"src":Object.values(_vm.front)[0].original_url,"alt":"front"}})])]):_c('media-library-attachment',{key:Object.keys(_vm.front)[0],staticClass:"attachment-government attachment-government-front mb-7 ",attrs:{"name":"attachment","after-upload":_vm.submitFront,"initial-value":_vm.front,"validation-errors":_vm.attachmentErrors,"validation-rules":{
                            accept: ['image/png', 'image/jpeg'],
                            maxSizeInKB: 1024*5,
                            minSizeInKB: 10
                            },"upload-domain":_vm.uploadDomain,"translations":Object.assign({}, _vm.translations, {selectOrDragMax: _vm.$t('Upload Government ID')})},on:{"change":_vm.onChangeFront}})],1):_vm._e(),(_vm.back)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h3',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t('Upload back')))]),_c('p',{staticClass:"mb-3"},[_vm._v("("+_vm._s(_vm.$t('JPG or PNG only'))+")")]),(_vm.isGovermentIdVerified)?_c('div',[_c('a',{attrs:{"href":Object.values(_vm.back)[0].original_url,"target":"_blank"}},[_c('img',{staticClass:"admin-preview",attrs:{"src":Object.values(_vm.back)[0].original_url,"alt":"back"}})])]):_c('media-library-attachment',{key:Object.keys(_vm.back)[0],staticClass:"attachment-government attachment-government-back mb-7 ",attrs:{"name":"attachment","after-upload":_vm.submitBack,"initial-value":_vm.back,"validation-errors":_vm.attachmentErrors,"validation-rules":{
                            accept: ['image/png', 'image/jpeg'],
                            maxSizeInKB: 1024*5,
                            minSizeInKB: 10
                            },"upload-domain":_vm.uploadDomain,"translations":Object.assign({}, _vm.translations, {selectOrDragMax: _vm.$t('Upload Government ID')})},on:{"change":_vm.onChangeBack}})],1):_vm._e()]),(_vm.isGovermentIdVerified)?_c('div',{staticClass:"flex text-center mt-10"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.$t('Your government ID has been verified'))+"! ")],1):_vm._e()]),_c('v-divider'),_c('v-card-actions',{staticClass:"px-10 py-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }