<template>
<v-col class="px-6">
    <v-alert
    elevation="0"
    class="pb-0 pl-0"
    >
    <template v-slot:prepend>
    <v-avatar
        color="grey lighten-1"
        size="48"
        class="mb-auto mr-3"
    >
        <span class="white--text">JN</span>
    </v-avatar>
    </template>
    <div class="d-flex flex-column">
        <span class="text-body-1">Trip requested</span>
        <h6 class="text-h6">{{event.full_name}}</h6>
        <span>Apartment Amor with Jacuzzi</span>
        <span>Feb 23 – 26 (3 nights)</span>
        <span>2 guests · € 231.36</span>
    </div>
    </v-alert>

    <!-- <v-divider class="my-4"></v-divider> -->
    <span class="divider-thick"></span>

    <h5 class="text-h5 font-weight-medium mb-3">Booking details</h5>
    <v-list class="py-0">
    <v-list-item two-line class="pl-0 my-2">
        <v-list-item-content>
        <v-list-item-title class="mb-2 font-weight-bold">Price</v-list-item-title>
        <v-list-item-subtitle class="font-weight-bold">€ 240.00</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line class="pl-0 my-2">
        <v-list-item-content>
        <v-list-item-title class="mb-2">Guests</v-list-item-title>
        <v-list-item-subtitle>2</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn text class="text-decoration-underline text-capitalize cursor-pointer">View</v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line class="pl-0 my-2">
        <v-list-item-content>
        <v-list-item-title class="mb-2">Check-in</v-list-item-title>
        <v-list-item-subtitle>Thu, Feb 23, 2023</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line class="pl-0 my-2">
        <v-list-item-content>
        <v-list-item-title class="mb-2">Check-out</v-list-item-title>
        <v-list-item-subtitle>Sun, Feb 26, 2023</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line class="pl-0 my-2">
        <v-list-item-content>
        <v-list-item-title class="mb-2">Booking date</v-list-item-title>
        <v-list-item-subtitle>Sun, Feb 12, 2023</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line class="pl-0 my-2">
        <v-list-item-content>
        <v-list-item-title class="mb-2">Confirmation code</v-list-item-title>
        <v-list-item-subtitle>HMX34Q9ZH2</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    </v-list>

    <span class="divider-thick"></span>
    <h5 class="text-h5 font-weight-medium mb-3">Guest paid</h5>
    <div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-space-between">
        <span>€ 80 x 3 nights</span>
        <span>€ 240.00</span>
    </div>
    <div class="d-flex align-items-center justify-space-between">
        <span>Guest service fee</span>
        <span>€ 39.98</span>
    </div>
    <div class="d-flex align-items-center justify-space-between mt-3">
        <span class="font-weight-bold">Total (EUR)</span>
        <span class="font-weight-bold">€ 231.36</span>
    </div>
    </div>
    <span class="divider-thick"></span>
    <h5 class="text-h5 font-weight-medium mb-3">Host payout</h5>
    <div class="d-flex flex-column">
    <div class="d-flex justify-space-between">
        <div class="d-flex flex-column mb-1">
        <span>3 nights room fee</span>
        <a class="text-decoration-underline text-body-2 font-weight-medium cursor-pointer">Show breakdowns</a>
        </div>
        <span>€ 240.00</span>
    </div>
    <div class="d-flex align-items-center justify-space-between">
        <span>Host service fee (3.0% + VAT)</span>
        <span>-€ 8.64</span>
    </div>
    <div class="d-flex align-items-center justify-space-between mt-3">
        <span class="font-weight-bold">Total (EUR)</span>
        <span class="font-weight-bold">€ 231.36</span>
    </div>
    </div>
</v-col>
</template>
<script>
export default {
  props: {
    event: {
      required: true,
      type: Object,
    },
  },
};
</script>
