<template>
    <v-col class="d-flex flex-column px-6">
      <div class="d-flex justify-space-between align-center mb-4">
        <h6 class="text-h6">{{ $t('Rule-sets') }}</h6>
        <v-btn
          class="text-decoration-underline font-weight-medium text-capitalize text-subtitle-1"
          text
          @click.stop="showRulesManagerModal = true"
        >
          {{ $t('Rule manager') }}
        </v-btn>
      </div>
      <p v-if="rulesAppliedOnSelectedRange.length === 0">
        {{ $t('Rule manager description') }}
      </p>

      <v-select
        :key="`s_${selectedKey}`"
        v-if="calendarRules && calendarRules.length"
        :value="ruleComputed"
        :items="ruleOptions"
        item-text="name"
        item-value="id"
        outlined
        @change="changeRule"
        hide-details
        class="mb-2"
      />
      <v-btn
        v-if="!calendarRules || !calendarRules.length"
        @click="showRulesModal = true"
        outlined
      >
        {{ $t('Create new rule') }}
      </v-btn>
      <v-card
        v-for="rule in rulesAppliedOnSelectedRange"
        :key="rule.calendar_rule_id"
        outlined
        class="my-2 py-3 px-5"
      >
        <div class="d-flex align-center">
          <v-sheet
            :color="rule.color"
            height="10"
            min-width="10"
            class="mr-2"
            style="border-radius: 9999px;"
          ></v-sheet>
          <v-card-subtitle
            class="px-0 py-0 font-weight-bold black--text text-subtitle-1 text-truncate"
          >
            {{ rule.name }}
          </v-card-subtitle>
        </div>
        <div>{{ rule.rangeString }}</div>
        <div v-if="rule.min_stay">{{ `${$t('Form Reservation MinStay')}: ${rule.min_stay}` }}</div>
        <div v-if="rule.price_adjustment">{{ `${$t('Price adjustment')}: ${rule.price_adjustment}%` }}</div>
      </v-card>

      <help-center-link mode="info" topic="calendar-rules" link-text="Kako upravljati pravilima?" cssClass="mt-5 mb-0" />

      <rules-manager-modal
          :show="showRulesManagerModal"
          :id="id"
          :calendarRules="calendarRules"
          @rulesChanged="$emit('rulesChanged')"
          @close="showRulesManagerModal = false"/>

      <rules-modal
          :show="showRulesModal"
          :id="id"
          :start="start"
          :end="end"
          @close="showRulesModal = false; selectedKey++"
          @rulesChanged=" $emit('rulesChanged'); showRulesModal = false;"
        />
      </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import RulesModal from '@/components/calendar/RulesModal.vue';
import RulesManagerModal from '@/components/calendar/RulesManagerModal.vue';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import {
  DATE_FORMAT,
  daysBetweenDates,
  humanFriendlyRangeString,
} from '@/components/calendar/config';

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
    calendarRules: {
      required: true,
      type: Array,
    },
    calendarRulesDates: {
      required: true,
      type: Object,
    },
  },
  components: {
    RulesModal,
    RulesManagerModal,
    HelpCenterLink,
  },
  data() {
    return {
      showRulesModal: false,
      showRulesManagerModal: false,
      rule: undefined,
      selectedKey: 0,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    startEnd() {
      return `${this.start}${this.end}`;
    },
    ruleComputed() {
      if (this.rulesAppliedOnSelectedRange.length === 0) {
        return 'NONE';
      }
      if (this.hasMixed || this.rulesAppliedOnSelectedRange.length >= 2) {
        // if selected daterange contains dates with rules and dates without rules
        return 'VARIOUS';
      }
      if (this.rulesAppliedOnSelectedRange.length === 1) {
        return this.rulesAppliedOnSelectedRange[0].id;
      }
      return 'NONE';
    },
    ruleOptions() {
      const hasVarius = (this.rulesAppliedOnSelectedRange.length >= 2 || (this.hasMixed && this.rulesAppliedOnSelectedRange.length >= 1))
        ? {
          name: this.$t('VARIOUS'),
          id: 'VARIOUS',
        }
        : {
          divider: true,
        };
      return [
        {
          name: this.$t('NONE'),
          id: 'NONE',
        },
        hasVarius,
        ...this.calendarRules.map((rule) => (
          {
            name: rule.name,
            id: rule.id,
          }
        )),
        {
          divider: true,
        },
        {
          name: `+ ${this.$t('Create new rule')}`,
          id: 'ADD',
        },
      ];
    },
    selectedRange() {
      return daysBetweenDates(this.start, moment(this.end).clone().subtract(1, 'days').startOf('day'));
    },
    hasMixed() { // check if selected daterange contains dates with rules and dates without rules
      let free = false;
      let notFree = false;
      for (let i = 0; i < this.selectedRange.length; i += 1) {
        const ruleApplyed = this.getRuleApplyedForDate(this.selectedRange[i]);
        if (ruleApplyed) {
          free = true;
        } else {
          notFree = true;
        }
      }
      return free && notFree;
    },
    rulesAppliedOnSelectedRange() {
      const rules = [];
      for (let i = 0; i < this.selectedRange.length; i += 1) {
        const ruleApplyed = this.getRuleApplyedForDate(this.selectedRange[i]);
        if (ruleApplyed && !rules.find((x) => x.calendar_rule_id === ruleApplyed.calendar_rule_id)) {
          const rule = JSON.parse(JSON.stringify(this.calendarRules.find((x) => x.id === ruleApplyed.rule_id)));
          // cut start & end
          if (this.start > ruleApplyed.rule_start) {
            rule.start = this.start;
          } else {
            rule.start = moment(ruleApplyed.rule_start).format(DATE_FORMAT);
          }

          if (this.end < ruleApplyed.rule_end) {
            rule.end = this.end;
          } else {
            rule.end = moment(ruleApplyed.rule_end).format(DATE_FORMAT);
          }

          rule.calendar_rule_id = ruleApplyed.calendar_rule_id;
          rule.rangeString = humanFriendlyRangeString(rule.start, rule.end, this.$i18n.locale);
          rules.push(rule);
        }
      }
      return rules;
      // return rules.sort((a, b) => a - b);
    },
  },
  methods: {
    getRuleApplyedForDate(date) {
      if (this.calendarRulesDates[date] && this.calendarRulesDates[date].rule_id) {
        return this.calendarRulesDates[date];
      }
      return null;
    },
    changeRule(selectedRuleOption) {
      if (selectedRuleOption === 'ADD') {
        this.showRulesModal = true;
        return;
      }
      if (selectedRuleOption === 'NONE') {
        this.none();
        return;
      }
      //  apply selected rule
      const rule = this.calendarRules.find((x) => x.id === selectedRuleOption);
      if (rule) {
        this.applyRule(rule);
      }
    },
    applyRule(rule) {
      // call api and apply rule for selected date range
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/rules/period/${this.id}/apply-rule`, {
          start: this.start,
          end: this.end,
          rule_id: rule.id,
        })
        .then(() => {
          this.$store.dispatch(
            'message',
            this.$t('Rule applied', { name: rule.name }),
          );
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
          this.$emit('rulesChanged');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    none() {
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/rules/period/${this.id}/none`, {
          start: this.start,
          end: this.end,
        })
        .then(() => {
          this.$store.dispatch(
            'message',
            this.$t('Rules removed'),
          );
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
          this.$emit('rulesChanged');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
  },
};

</script>
