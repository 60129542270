<template>
    <v-col class="px-3">
        <v-card class="px-3 py-3 rounded-lg mt-5 cursor-pointer text-center text-lg-left" elevation="0">
            <v-icon class="mr-3" color="black" large>
                mdi-calendar-edit-outline
            </v-icon>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center justify-center justify-lg-start">
                    <span class="text-h6 font-weight-medium">
                      {{ $t('Calendar Sidebar Empty State Title') }}
                    </span>
                </div>
                <span class="text-body-2 font-weight-light">
                  {{ $t('Calendar Sidebar Empty State Description') }}
                </span>
            </div>
            <help-center-link mode="info" topic="calendar-availability" link-text="Kako ažurirati raspoloživost?" cssClass="mt-3 mb-0" />
        </v-card>
        <!-- <div class="d-flex w-full justify-center justify-lg-start">
            <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 mt-3 px-2 py-2" color="white"
                elevation="0" link>
                Give feedback
            </v-btn>
        </div> -->
    </v-col>
</template>

<script>
import HelpCenterLink from '@/components/HelpCenterLink.vue';

export default {
  components: {
    HelpCenterLink,
  },
};
</script>
