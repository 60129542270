<template>
  <modal
      :show="show"
      :title="`Reservation Email & SMS Templates`"
      @save="close"
      saveText="Close"
      @close="close"
      :fullscreen="true"
      :sticky-header="true"
  >
      <v-container  class="px-5" :fluid="true">
        <v-radio-group row v-model="template" class="mt-0 pl-5 text-capitalize">
          <template v-if="templates && templates.templates">
            <v-radio
              v-for="(tmp, key) in templates.templates"
              :key="key"
              :value="key"
              :label="tmp.name"
            />
          </template>
        </v-radio-group>

        <v-radio-group row v-model="locale" class="mt-0 pl-5 text-capitalize">
            <v-radio :value="'sr'" label="Serbian"/>
            <v-radio :value="'en'" label="English"/>
        </v-radio-group>

        <!-- <component
          :is="templateComponent" :template="templates.templates[template]"
          v-if="templates && templates.templates && templates.templates[template]"
        /> -->
        <templateBasic
          v-if="templates && templates.templates && templates.templates[template]"
          :template="templates.templates[template]"
          :locale="locale"
        />

      </v-container>
  </modal>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/Modal.vue';
import TemplateBasic from '@/components/reservations/templates/Basic.vue';

export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    reservationId: {
      required: true,
    },
  },
  components: {
    Modal,
    TemplateBasic,
  },
  data() {
    return {
      template: 'reservation_basic',

      templates: undefined,
      locale: 'sr',
    };
  },
  computed: {
    // templateComponent() {
    //   if (this.template === 'reservation_basic') {
    //     return TemplateBasic;
    //   }
    //   return undefined;
    //   // else if (this.template === 'reservation_with_advance_payment'){
    //   //   return TemplateWithAdvancePayment;
    //   // }
    // },
  },
  mounted() {
    this.loadTemplates();
  },
  methods: {
    loadTemplates() {
      this.$store.dispatch('loading', true);
      axios
        .get(`/calendar/reservations/${this.reservationId}/templates`)
        .then(({ data }) => {
          // console.log('stigao response');
          // console.log(data);
          this.templates = data;
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    save() {
      // console.log('save');
    },
    close() {
      // this.show = false;
      this.$emit('close');
    },
  },
};
</script>
