<template>
  <main-layout
    :containerFluid="true"
    :hide-alerts="true"
    title="">
      <template v-slot:body>
        <div  class="flex-container">
          <div
            v-if="tv && tv.pozvati_vlasnike_koji_nisu_odgovorili_na_upit"
            class="flex-item" style="border-right:1px solid black; border-bottom:1px solid black">
            <h2>Vlasnik nije odg na Upit ({{ tv.pozvati_vlasnike_koji_nisu_odgovorili_na_upit.length }})</h2>
            <inquries-in-table
              :inquries="tv.pozvati_vlasnike_koji_nisu_odgovorili_na_upit"/>
          </div>
          <div class="flex-item" style="border-bottom:1px solid black"
            v-if="tv && tv.pozvati_goste_koji_su_poslali_upit_a_vlasnik_je_odgovorio_da_je_zauzet_apartman"
          >
            <h2>Goste poslalo upit - vlasnik odg apa <b>zauzet</b> ({{ tv.pozvati_goste_koji_su_poslali_upit_a_vlasnik_je_odgovorio_da_je_zauzet_apartman.length }})</h2>
            <inquries-in-table
              :inquries="tv.pozvati_goste_koji_su_poslali_upit_a_vlasnik_je_odgovorio_da_je_zauzet_apartman"/>
          </div>
          <div class="flex-item" style="border-right:1px solid black;"
            v-if="tv && tv.pozvati_goste_koji_su_poslali_upit_a_vlasnik_je_odgovorio_da_je_slobodan_apartman">
            <h2>Gosti upit, Vlasnik odg apa <b>Slobodan</b> ({{ tv.pozvati_goste_koji_su_poslali_upit_a_vlasnik_je_odgovorio_da_je_slobodan_apartman.length }})</h2>
            <inquries-in-table
              :inquries="tv.pozvati_goste_koji_su_poslali_upit_a_vlasnik_je_odgovorio_da_je_slobodan_apartman"/>
          </div>
          <div class="flex-item" style="" v-if="tv && tv.getUnresolvedInstantInquries">
            <h2>Instant Upiti ({{ tv.getUnresolvedInstantInquries.length }})</h2>
            <inquries-in-table
              :inquries="tv.getUnresolvedInstantInquries"/>
          </div>
      </div>
      <inqury-preview-modal @reload="load"/>
    </template>
  </main-layout>
</template>
<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  overflow: hidden;
}

.flex-item {
  min-width: 50%;
  max-width: 50%;
  width:50%;
  overflow-y: auto;
  min-height: 50%;
  max-height: 50%;
  height: 50%;
}
</style>
<script>
import axios from 'axios';
import InquriesInTable from '@/components/inquries/InquriesInTable.vue';
import InquryPreviewModal from '@/components/inquries/InquryPreviewModal.vue';
import TvHelpersMixin from '@/lib/TvHelpers.mixin';

export default {
  mixins: [TvHelpersMixin],
  components: {
    InquryPreviewModal,
    InquriesInTable,
  },
  data() {
    return {
      tv: undefined,
    };
  },
  mounted() {
    this.$title = 'TV';
    this.load();

    this.setAutoRefreshInterval(this.load);
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      axios
        .get('/calendar/tv')
        .then(({ data }) => {
          this.tv = data;
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.log('ERror 23: ', error);
          // this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
  },
};
</script>
