<template>
  <filter-wrapper
    v-show="monthRange"
    :title="title"
    :isFilterApplied="true"
    :showCancel="true"
    @update="changeMonth"
    @cancel="cancel"
    cssClass="large-button"
  >
    <template v-slot:main>
      <v-list
        class="px-10"
        :class="$vuetify.breakpoint.mdAndUp ? 'overflow-y-auto' : 'mb-16'"
        :max-height="$vuetify.breakpoint.mdAndUp ? 400 : ''"
      >
        <v-radio-group v-model="valueFormated" class="mt-0" :key="key">
          <v-radio
            v-for="month in monthRange"
            :value="month.value"
            :label="month.label"
            :key="month.value"
            class="my-4"
          />
        </v-radio-group>
      </v-list>
    </template>
  </filter-wrapper>
</template>

<script>
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import moment from 'moment';

export default {
  components: {
    FilterWrapper,
  },
  props: {
    start: {
      required: true,
    },
    end: {
      required: true,
    },
    rangeStart: {
      required: true,
    },
    validRangeEnd: {
      required: true,
    },
    value: {
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      select: undefined,
      key: 0,
    };
  },
  computed: {
    monthSwitcherStart() {
      if (moment(this.start) > moment().startOf('month')) {
        return moment().startOf('month');
      }
      return moment(this.start);
    },
    monthRange() {
      const startDate = moment(this.start);
      const endDate = moment(this.end);

      const months = [];

      if (startDate < endDate) {
        // const date = startDate.startOf('month');
        // add prev 3 months
        const monthsBefore = this.monthSwitcherStart.clone().subtract(3, 'months');
        const monthsEnd = endDate.clone().add(6, 'months');
        const date = monthsBefore.startOf('month');

        while ((date < monthsEnd.endOf('month')) && (date <= moment(this.validRangeEnd).subtract(1, 'months'))) {
          months.push({
            // eslint-disable-next-line
            value: moment(date).locale(this.$i18n.locale).format('MMMM YYYY'),
            date: moment(date).clone(),
            // eslint-disable-next-line
            label: moment(date).locale(this.$i18n.locale).format(this.$i18n.locale === 'sr' ? 'MMMM YYYY.' : 'MMMM YYYY'),
            loadedInCalendarAlready: !(date < startDate || date >= endDate),
          });
          date.add(1, 'month');
        }
        // add next 12 months
        // endDate.add(1, 'years');
      }
      return months;
    },
    valueFormated: {
      get() {
        return moment(this.value).clone().locale(this.$i18n.locale).format('MMMM YYYY');
      },
      set(newName) {
        this.select = newName;
      },
    },
  },
  methods: {
    cancel() {
      this.key += 1;
    },
    changeMonth() {
      const month = this.monthRange.find((m) => m.value === this.select);
      if (!month) {
        return;
      }
      if (month.loadedInCalendarAlready) {
        this.$emit('scrollToMonth', month.date);
      } else {
        this.$emit('updateCalendarStartDate', month.date);
      }
    },

  },
};
</script>
