<template>
    <v-col class="d-flex flex-column px-6">
        <h6 class="text-h6 mb-4">{{ $t('Price') }}</h6>
        <v-text-field
            v-model="price"
            @keypress="allowOnlyWholeInputNumbers"
            :placeholder="priceRangeString"
            :error-messages="firstError('price')"
            :min="1"
            outlined
            persistent-hint
            type="number"
            :hint="$t('Nightly price')"
            prepend-inner-icon="mdi-currency-eur"
            class="mb-3"
        >
            <template v-slot:append>
                <v-btn
                    @click.prevent="save"
                    color="white"
                    elevation="0"
                    style="position: absolute; right: 8px; top: 0; bottom: 0; margin: auto;"
                    class="text-decoration-underline text-capitalize text-subtitle-1 font-weight-medium px-2 py-2"
                  >
                    {{ $t('Save') }}
                </v-btn>
            </template>
        </v-text-field>

        <!-- ({{ priceRangeString }}) - {{ priceRange }} -->
        <div
          v-if="loadingCalculations === true || calculator === undefined"
          class="d-flex justify-center py-10"
        >
          <v-progress-circular indeterminate size="6" class="mr-2"/>
          <v-progress-circular indeterminate size="6" class="mr-2"/>
          <v-progress-circular indeterminate size="6" class="mr-2"/>
        </div>
        <div v-else>
          <v-alert
            elevation="0"
            class="pl-0 pt-0 pb-0 mb-0"
          >
            <template v-slot:prepend>
                <v-icon color="black" class="mr-3 mb-auto">
                    mdi-credit-card-outline
                </v-icon>
            </template>
            <div class="d-flex flex-column">
                <p>
                  {{ $t('Guests pay') }}
                  <strong>
                    {{ ` €${calculator.total} ` }}
                  </strong>
                  {{ $t('after taxes and fees') + '.' }}
                  {{ $t('You’ll earn') }}
                  <strong>
                    {{ ` €${calculator.ownerTotal}` }}
                  </strong>
                  .
                </p>
            </div>
          </v-alert>
          <div class="d-flex align-center">
            <div>
              <v-btn
                @click="showPricingModal = true"
                text
                class="mr-auto ml-5 text-transform-none text-subtitle-1 text-decoration-underline font-weight-medium"
              >
                {{ $t('Pricing Preview Title') }}
              </v-btn>
            </div>
            <help-center-link topic="calendar-pricing-preview" mode="button" class="ml-0.5"/>
          </div>
        </div>

        <help-center-link mode="info" topic="calendar-price-per-day" link-text="Kako postaviti cenu po danu?" cssClass="mt-5 mb-0" />

        <pricing-preview
          v-if="calculator && start && end"
          :key="`${calculator.start}${calculator.end}`"
          :show="showPricingModal"
          :id="id"
          :start="start"
          :end="end"
          :calculator-data="calculator"
          @close="showPricingModal = false"
        />
          <!-- :key="`${start}${end}`" -->

    </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import PricingPreview from '@/components/PricingPreview.vue';
// import ReservationModal from '@/components/reservations/ReservationModal.vue';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import CalendarMixin from '@/components/calendar/CalendarMixin';
import {
  daysBetweenDates,
  allowOnlyWholeInputNumbers,
} from '@/components/calendar/config';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import {
  required,
  maxValue,
  minValue,
} from 'vuelidate/lib/validators';
import FormValidationMixin from '@/lib/FormValidationMixin';

export default {
  mixins: [CalendarMixin, validationMixin, FormValidationMixin],
  components: {
    PricingPreview,
    HelpCenterLink,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
    specialPricing: {
      required: true,
      type: Object,
    },
    calendarRules: {
      required: true,
      type: Array,
    },
  },
  validations() {
    return {
      price: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(10000),
      },
    };
  },
  watch: {
    // watch if start or end is changed and reset price
    startEnd() {
      this.price = undefined;
      this.loadingCalculations = true;
      this.debounceGetPrice();
    },
    calendarRules() {
      this.calculator = undefined;
      this.loadingCalculations = true;
      this.debounceGetPrice();
    },
  },
  data() {
    return {
      price: undefined,
      showPricingModal: false,
      calculator: undefined,
      loadingCalculations: true,
    };
  },
  mounted() {
    this.debounceGetPrice();
  },
  computed: {
    ...mapGetters(['loading', 'isAdmin']),
    startEnd() {
      return `${this.start}${this.end}`;
    },
    selectedRange() {
      return daysBetweenDates(this.start, moment(this.end).clone().subtract(1, 'days').startOf('day'));
    },
    priceRange() {
      const prices = [];
      for (let i = 0; i < this.selectedRange.length; i += 1) {
        const price = this.getPriceForDate(this.selectedRange[i]);
        prices.push(price);
      }
      return prices.sort((a, b) => a - b);
    },
    priceRangeString() {
      // remove duplicates from array
      const unique = [...new Set(this.priceRange)];
      if (unique.length === 1) {
        return unique[0].toString();
      } if (unique.length > 1) {
        return `${unique[0]} - ${unique[unique.length - 1]}`;
      }
      return '';
    },
  },
  methods: {
    allowOnlyWholeInputNumbers,
    debounceGetPrice: _.debounce(function () {
      this.getPrice();
    }, 1000),
    getPrice() {
      this.$store
        .dispatch('calculatePrice', {
          id: this.id,
          start: this.start,
          end: this.end,
          guests: 1,
          parking: 0,
        })
        .then((data) => {
          this.calculator = data;
          this.loadingCalculations = false;
        })
        .catch((error) => {
          console.warn('ERROR');
          console.log(error);
          this.loadingCalculations = false;
        });
    },
    save() {
      // forbid double clicking...
      if (this.loading || !this.price) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/special-price/${this.id}`, {
          start: this.start,
          end: this.end,
          price: this.price,
        })
        .then(() => {
          this.$v.$reset();
          this.price = undefined;
          this.$emit('priceChanged');
          this.$store.dispatch('setErrors', []);
          this.$store.dispatch('loading', false);
          // reload pricing preview
          this.calculator = undefined;
          this.loadingCalculations = true;
          this.debounceGetPrice();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoading = false;
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
  },
};
</script>
