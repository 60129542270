<template>
<div>
    <v-row>
        <v-col cols="12">
            <h2 class="text-center mt-5 mb-3">{{template.name}}</h2>
        </v-col>
    </v-row>
    <v-row v-for="templateType in ['sms', 'email']" :key="templateType">
        <v-col cols="6"
            v-for="templateFor in ['guest', 'owner']"
            :key="templateFor"
            class="mb-5">
            <h3 class="text-center mb-3">{{templateType.toLocaleUpperCase()}} ({{ templateFor.toLocaleUpperCase() }})</h3>
            <card
                :copiedId="copiedId"
                @copiedId="updateCopiedId"
                :template="template"
                :type="templateType"
                :for="templateFor"
                :locale="locale"
                v-if="templateType === 'sms'"
            />
            <div v-else>
              <div class="mb-5 text-h5">{{ template[locale][templateType][templateFor]['subject'] }}</div>
              <div
                v-html="template[locale][templateType][templateFor]['body']"
                style="border:1px solid black;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);"
                >
              </div>
            </div>
        </v-col>
    </v-row>
</div>

</template>
<script>
import Card from '@/components/reservations/templates/Card.vue';

export default {
  props: {
    template: {
      required: true,
      type: Object,
    },
    locale: {
      required: true,
      type: String,
    },
  },
  components: {
    Card,
  },
  data() {
    return {
      copiedId: null,
    };
  },
  computed: {
  },
  methods: {
    updateCopiedId(id) {
      this.copiedId = id;
      setTimeout(() => {
        this.copiedId = null;
      }, 2500);
    },
  },
};
</script>
<style scoped>
</style>
