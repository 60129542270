<template>
    <main-layout title="Edit Reservations" :containerFluid="true">
      <template v-slot:body>
        <reservation-modal-edit :showInModal="true" v-if="isAdmin"/>
      </template>
    </main-layout>
</template>

<script>
import ReservationModalEdit from '@/components/reservations/ReservationModalEdit.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    reservationId: {
      required: true,
      type: String,
    },
  },
  components: {
    ReservationModalEdit,
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  data() {
    return {
    };
  },
  mounted() {
    this.$title = 'Edit Reservations';

    this.$store.dispatch('editReservationId', this.reservationId);
  },
  methods: {

  },
};

</script>
