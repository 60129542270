<template>
    <modal
        :show="show"
        :title="title"
        @save="deleteReservationSubmit"
        saveText="Delete"
        @close="close"
        :fullscreen="false"
        :sticky-header="true"
    >
        <v-container class="px-5">

            <v-radio-group
                v-model="form.delete_reason"
                class="text-capitalize mb-5"
                :error-messages="firstError('form.delete_reason')"
            >
                <v-radio v-for="(reason, index) in cancellationReasons"
                    :key="index"
                    :value="reason.value"
                    :error-messages="firstError('form.delete_reason')"
                    :label="reason.label"
                />
            </v-radio-group>

            <div v-if="deleteReservation && deleteReservation.advance_payment">
              <v-divider></v-divider>
              <v-chip :color="deleteReservation.advance_payment_paid ? 'green' : 'red'" text-color="white">
                AVANAS {{ deleteReservation.advance_payment_amount }}€ {{deleteReservation.advance_payment_paid ? 'Plaćen' : 'Nije Plaćen'}}
              </v-chip>

              <br/>
              <div v-if="deleteReservation.advance_payment_paid">
                Da li će vlasnik refundirati?
                <v-radio-group
                  v-model="form.advance_payment_refund"
                  class="text-capitalize mb-5"
                  :error-messages="firstError('form.advance_payment_refund')"
                >
                  <v-radio
                      :value="0"
                      label="NO REFUND"
                      :error-messages="firstError('form.advance_payment_refund')"
                  />
                  <v-radio
                      :value="1"
                      label="REFUND"
                      :error-messages="firstError('form.advance_payment_refund')"
                  />
                </v-radio-group>
              </div>
            </div>

            <hr>
            <v-textarea
                v-model="form.delete_comment"
                :error-messages="firstError('form.delete_comment')"
                placeholder="Type why they cancel reservation"
                label="Comment"
                class=""
                outlined
                filled
            ></v-textarea>

        </v-container>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import FormValidationMixin from '@/lib/FormValidationMixin';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  mixins: [validationMixin, FormValidationMixin],
  components: {
    Modal,
  },
  validations: {
    form: {
      delete_reason: {
        required,
      },
      delete_comment: {
        required,
        txtMinLen: minLength(10),
      },
      advance_payment_refund: {
        youMustSelectAtLeastOneRule: requiredIf(function () {
          return this.deleteReservation.advance_payment && this.deleteReservation.advance_payment_paid;
        }),
      },
    },
  },
  data() {
    return {
      show: false,
      form: {
        delete_reason: undefined,
        delete_comment: undefined,
        advance_payment_refund: undefined,
      },
    };
  },
  watch: {
    deleteReservation(reservation) {
      if (reservation && reservation.id) {
        this.show = true;
      }
    },
  },
  computed: {
    ...mapGetters(['deleteReservation']),
    cancellationReasons() {
      return [
        { value: 'guest_cancelled', label: 'Guest cancelled' },
        { value: 'owner_cancelled', label: 'Owner cancelled (Justifiable)' },
        { value: 'owner_double', label: 'Owner double booking' },
        { value: 'bw_cancel', label: 'BW cancellation' },
        { value: 'no_show', label: 'Guest did not show up (No show)' },
        { value: 'advance_payment_not_paid', label: 'Upfront payment not paid' },
      ];
    },
    title() {
      if (this.deleteReservation && this.deleteReservation.id) {
        return `Delete Reservation: #${this.deleteReservation.id}`;
      }
      return 'Delete Reservation';
    },
  },
  methods: {
    deleteReservationSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .delete(`calendar/reservations/${this.deleteReservation.id}`, {
          data: {
            ...this.form,
          },
        })
        .then(() => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', 'Reservation Deleted');
          this.$emit('removeReservationFromSidebar');
          this.close();
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    close() {
      this.show = false;
      this.form.delete_reason = undefined;
      this.form.delete_comment = undefined;
      this.$store.dispatch('clearDeleteReservation');
      this.$v.$reset();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
