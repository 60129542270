<template>
  <main-layout
    :containerFluid="true"
    :hide-alerts="true"
    title="">
      <template v-slot:body>
          <div
            v-if="reservations" style="border-right:1px solid black; border-bottom:1px solid black">
            <h2>Advance Payment Expired ({{ reservations.length }})</h2>
            <reservations-in-table  :reservations="reservations"/>
            <reservation-preview-modal/>
          </div>
      </template>
  </main-layout>
</template>
<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 110px);
}

.flex-item {
  min-width: 50%;
  max-width: 50%;
  width:50%;
}
</style>
<script>
import axios from 'axios';
import ReservationPreviewModal from '@/components/reservations/ReservationPreviewModal.vue';
import ReservationsInTable from '@/components/reservations/ReservationsInTable.vue';
import TvHelpersMixin from '@/lib/TvHelpers.mixin';

export default {
  mixins: [TvHelpersMixin],
  components: {
    ReservationPreviewModal,
    ReservationsInTable,
  },
  data() {
    return {
      reservations: undefined,
    };
  },
  mounted() {
    this.$title = 'Advance Payment Expired';
    this.load();

    this.setAutoRefreshInterval(this.load);
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      axios
        .get('/calendar/tv-advance-payment-expired')
        .then(({ data }) => {
          this.tv = data;
          this.reservations = data;
          // console.log('stigao data', data);
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.log('ERror 23: ', error);
          // this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
  },
};
</script>
