<template>
    <v-col class="d-flex flex-column px-6">
        <div class="d-flex flex-column">
            <div class="d-flex align-center justify-space-between">
                <span class="calendar-sidebar-selected-date">
                  {{ startEndString }}
                </span>
                <date-picker-sidebar
                  @updateStartEnd="$emit('updateStartEnd', $event)"
                  :start="start"
                  :end="end"
                  :events="events"
                />
            </div>
            <div class="mt-4 d-flex">
                <span class="text-h6 mr-auto">
                    {{ $t('Available') }}
                </span>
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.prevent="isBlockDisabled ? null : block()"
                        :class="{'disable-events': isBlockDisabled}"
                        :dark="isBlockDisabled"
                        :outlined="!isBlockDisabled"
                        v-bind="attrs"
                        v-on="$vuetify.breakpoint.smAndUp && on"
                        class="elevation-0"
                        fab
                        x-small
                      >
                          <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Not available') }}</span>
                  </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click.prevent="isOpenDisabled ? null  : open()"
                          :class="{'disable-events': isOpenDisabled}"
                          :dark="isOpenDisabled"
                          :outlined="!isOpenDisabled"
                          v-bind="attrs"
                          v-on="$vuetify.breakpoint.smAndUp && on"
                          class="ml-4 elevation-0"
                          fab
                          x-small
                        >
                            <v-icon dark>mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('Available') }}</span>
                    </v-tooltip>
                </div>
            </div>
            <div v-if="isMixed" class="text-body-2 mt-1">
              {{ $t('Open') }}: {{ $tc('days_count', stats.freeCount) }}
              <br/>
              {{ $t('Unavailable') }}: {{ $tc('days_count', stats.manualCount) }}
            </div>
            <v-btn
              v-if="isAdmin && canReserve"
              class="mt-6"
              outlined
              @click.stop="$store.commit('toggleReservationModal')"
            >Reserve</v-btn>
        </div>
    </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import DatePickerSidebar from '@/components/calendar/DatepickerSidebar.vue';
import moment from 'moment';
import axios from 'axios';
import {
  EVENT_TYPE_MANUAL,
  EVENT_TYPE_MIN_STAY_BLOCKED,
  daysBetweenDates,
  startEndString,
} from '@/components/calendar/config';

export default {
  components: {
    DatePickerSidebar,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
    events: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['loading', 'isAdmin']),
    isBlockDisabled() {
      return this.stats.totalCount === this.stats.manualCount;
    },
    isOpenDisabled() {
      return this.stats.totalCount === this.stats.freeCount;
    },
    isMixed() {
      return this.stats.freeCount > 0 && this.stats.manualCount > 0;
    },
    canReserve() {
      return this.stats.manualCount === 0 && this.stats.minStayBlockCount === 0 && this.stats.totalCount > 0;
    },
    stats() {
      let freeCount = 0;
      let manualCount = 0;
      let minStayBlockCount = 0;
      const free = [];
      const manual = [];
      const minStayBlock = [];
      for (let i = 0; i < this.selectedRange.length; i += 1) {
        if (this.allManualDatesFromEvents.includes(this.selectedRange[i])) {
          manualCount += 1;
          manual.push(this.selectedRange[i]);
        } else {
          if (this.minStayBlockedDatesFromEvents.includes(this.selectedRange[i])) {
            minStayBlockCount += 1;
            minStayBlock.push(this.selectedRange[i]);
          }
          freeCount += 1;
          free.push(this.selectedRange[i]);
        }
      }
      return {
        free,
        manual,
        freeCount,
        manualCount,
        minStayBlockCount,
        minStayBlock,
        totalCount: this.selectedRange.length,
      };
    },
    selectedRange() {
      return daysBetweenDates(this.start, moment(this.end).clone().subtract(1, 'days').startOf('day'));
    },
    startEndString,
    allManualDatesFromEvents() {
      const dates = [];
      this.events.forEach((event) => {
        if (event.type === EVENT_TYPE_MANUAL) {
          const eventDates = daysBetweenDates(
            event.start,
            moment(event.end).clone().subtract(1, 'days').startOf('day'),
          );
          for (let i = 0; i < eventDates.length; i += 1) {
            dates.push(eventDates[i]);
          }
        }
      });
      return dates.sort((a, b) => moment(a).diff(moment(b)));
    },
    minStayBlockedDatesFromEvents() {
      const dates = [];
      this.events.forEach((event) => {
        if (event.type === EVENT_TYPE_MIN_STAY_BLOCKED) {
          const eventDates = daysBetweenDates(
            event.start,
            moment(event.end).clone().subtract(1, 'days').startOf('day'),
          );
          for (let i = 0; i < eventDates.length; i += 1) {
            dates.push(eventDates[i]);
          }
        }
      });
      return dates.sort((a, b) => moment(a).diff(moment(b)));
    },
  },
  data() {
    return {
      showDatePicker: false,
    };
  },
  methods: {
    clearDate(date) {
      if (document.querySelector(`td[data-date="${date}"]`)) {
        const td = document.querySelector(`td[data-date="${date}"]`);
        delete td.dataset.eventType;
        delete td.dataset.eventId;
        td.classList.remove('has-event');
        td.classList.remove('event-manual');
      }
    },
    open() {
      // forbid double clicking...
      if (this.loading) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/manual/open/${this.id}`, {
          start: this.start,
          end: this.end,
        })
        .then(() => {
          this.selectedRange.forEach((date) => this.clearDate(date));

          this.$emit('refetchEvents');
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Available for', { date: this.startEndString }));
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoading = false;
          this.$store.dispatch('loading', false);
        });
    },
    block() {
      // forbid double clicking...
      if (this.loading) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/manual/block/${this.id}`, {
          start: this.start,
          end: this.end,
        })
        .then(() => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Unavailable for', { date: this.startEndString }));
          this.$emit('refetchEvents');
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoading = false;
          this.$store.dispatch('loading', false);
        });
    },
  },
};
</script>
