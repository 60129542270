<template>
    <div>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndDown"
          @click.prevent="openDatePickerModal"
          color="white"
          elevation="0"
          class="text-decoration-underline text-capitalize text-subtitle-1 font-weight-medium px-2 py-2"
        >
          {{ $t('Change dates') }}
        </v-btn>
        <modal
          v-if="showDatePicker && $vuetify.breakpoint.mdAndDown"
          @close="closeDatePickerModal"
          @clear="clearDatePickerSelection"
          @save="save"
          :show="showDatePicker && $vuetify.breakpoint.mdAndDown"
          :title="datePickerModalTitle"
          :sticky-header="true"
          :fullscreen="true"
          :show-actions-clear="true"
          :clear-text="'Clear'"
          saveText="Apply"
          hide-actions-cancel
          :cssClass="`${isSaveButtonDisabled ? 'disable-save' : ''} calendar-datepicker`"
        >
          <div class="modal-datepicker-component-wrapper">
            <date-picker
              ref="calendarDatepicker"
              :disabled-dates="disabledDates"
              :manual-block-dates="manualBlockDates"
              :min-stay-block-dates="minStayBlockDates"
              :start="datePickerRange.start"
              :end="datePickerRange.end"
              @updateStartEnd="updateSelection"
            />
          </div>
        </modal>
    </div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import DatePicker from '@/components/DatePickerComponent.vue';
import moment from 'moment';
import {
  DAY_NAMES,
  DATE_FORMAT,
  daysBetweenDates,
  EVENT_TYPE_MANUAL,
} from '@/components/calendar/config';

export default {
  props: {
    start: {
      required: true,
    },
    end: {
      required: true,
    },
    events: {
      required: true,
      type: Array,
    },
  },
  components: {
    DatePicker,
    Modal,
  },
  data() {
    return {
      showDatePicker: false,
      datePickerRange: {
        start: undefined,
        end: undefined,
      },
      datePickerNumberOfRows: 1,
      datePickerNumberOfMonths: 3,
      dayNames: DAY_NAMES,
    };
  },
  computed: {
    manualBlockDates: {
      cache: false,
      get() {
        const dates = [];
        this.events.forEach((event) => {
          if (event.type === EVENT_TYPE_MANUAL) {
            const eventDates = daysBetweenDates(
              event.start,
              moment(event.end).clone().subtract(1, 'days').startOf('day'),
            );
            for (let i = 0; i < eventDates.length; i += 1) {
              dates.push(eventDates[i]);
            }
          }
        });
        return dates.sort((a, b) => moment(a).diff(moment(b)));
      },
    },
    disabledDates() {
      const dates = [];
      this.events.forEach((event) => {
        if (event.type !== EVENT_TYPE_MANUAL && event.type !== 'min_stay_blocked') {
          const eventDates = daysBetweenDates(
            event.start,
            moment(event.end).clone().subtract(1, 'days').startOf('day'),
          );
          for (let i = 0; i < eventDates.length; i += 1) {
            dates.push(eventDates[i]);
          }
        }
      });
      return dates.sort((a, b) => moment(a).diff(moment(b)));
    },
    minStayBlockDates() {
      const dates = [];
      this.events.forEach((event) => {
        if (event.type === 'min_stay_blocked') {
          const eventDates = daysBetweenDates(
            event.start,
            moment(event.end).clone().subtract(1, 'days').startOf('day'),
          );
          for (let i = 0; i < eventDates.length; i += 1) {
            dates.push(eventDates[i]);
          }
        }
      });
      return dates.sort((a, b) => moment(a).diff(moment(b)));
    },
    isMinStayBlockSelected() {
      return Boolean(this.events.find((event) => event.type === 'min_stay_blocked'
        && (this.start >= event.start
        && this.end <= event.end)));
    },
    datePickerRangeNumberOfNights() {
      if (this.datePickerRange.start && this.datePickerRange.end) {
        return daysBetweenDates(
          this.datePickerRange.start,
          moment(this.datePickerRange.end).clone().subtract(1, 'days').startOf('day'),
        ).length;
      }
      return null;
    },
    isSaveButtonDisabled() {
      if (
        !this.datePickerRange
        || (!this.datePickerRange.start && !this.datePickerRange.end)
      ) {
        return true;
      }
      return false;
    },
    datePickerModalTitle() {
      return this.datePickerRangeNumberOfNights
        ? this.$tc('nights_count', this.datePickerRangeNumberOfNights)
        : this.$t('Select dates');
    },
  },
  methods: {
    updateSelection(dates) {
      this.datePickerRange.start = moment(dates.start).clone().format(DATE_FORMAT);
      this.datePickerRange.end = moment(dates.end).add(1, 'days').clone().format(DATE_FORMAT);
    },
    clearDatePickerDates() {
      this.datePickerRange = {
        start: undefined,
        end: undefined,
      };
    },
    clearDatePickerSelection() {
      this.clearDatePickerDates();
      this.$refs.calendarDatepicker.picker.clear();
    },
    closeDatePickerModal() {
      this.clearDatePickerDates();
      this.showDatePicker = false;
    },
    openDatePickerModal() {
      this.showDatePicker = true;
      this.datePickerRange = {
        start: moment(this.start).clone().format(DATE_FORMAT),
        end: moment(this.end).clone().format(DATE_FORMAT),
      };
    },
    save() {
      this.$emit('updateStartEnd', {
        start: this.datePickerRange.start,
        end: moment(this.datePickerRange.end).subtract(1, 'days').clone().format(DATE_FORMAT),
      });
      this.closeDatePickerModal();
    },
  },
};
</script>

<style scoped lang="scss">
  .modal-datepicker-component-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
  }
</style>
