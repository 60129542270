var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.initCompleted && Object.keys(_vm.apartment).length === 0)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-10"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Apartment not found')))]),_c('br'),_c('br'),_c('router-link',{attrs:{"to":{ name: 'AdsCreate'}}},[_vm._v(_vm._s(_vm.$t('Create new apartment')))])],1)],1)],1)],1):(_vm.initCompleted && _vm.apartment.isWizzardCompleted === false)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-10"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Calendar Empty Listing Title')))]),_c('br'),_c('br'),_c('router-link',{attrs:{"to":{ name: 'AdForm', params: {
            id: this.$route.params.id,
            categoryConst: this.$route.params.categoryConst,
            stepSlug: _vm.apartment.status,
          }}}},[_vm._v(_vm._s(_vm.$t('Calendar Empty Listing Description')))])],1)],1)],1)],1):(_vm.initCompleted)?_c('div',[_c('div',[_c('v-card',{staticClass:"d-flex flex-column justify-center",staticStyle:{"position":"sticky","border-bottom":"1px solid lightgray !important","z-index":"5"},style:(_vm.$vuetify.breakpoint.mdAndDown ? 'top: 56px !important;' : 'top: 64px !important; padding-right:370px;'),attrs:{"flat":""}},[_c('div',{staticClass:"d-flex w-100 justify-end px-3 px-sm-5 py-4 pb-3 align-start align-sm-center"},[_c('div',{staticClass:"d-sm-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[(_vm.isCalendarLoaded)?_c('month-switcher',{attrs:{"start":_vm.calendar.view.activeStart,"end":_vm.calendar.view.activeEnd,"range-start":_vm.monthSwitcherStart,"title":_vm.monthName,"value":_vm.currentMonthNameDate,"valid-range-end":_vm.validRangeEnd},on:{"updateCalendarStartDate":_vm.updateCalendarStartDate,"scrollToMonth":_vm.scrollToMonth}}):_vm._e(),_c('div',{staticClass:"mt-2 mt-sm-0"},[(_vm.isCalendarLoaded)?_c('filter-ads',{key:'filter_calendar',attrs:{"offsetPositionLeft":true,"isMultiSelect":false,"showCancel":true,"value":Number(_vm.$route.params.id),"status-filter":['pending', 'pending_fix', 'listed', 'unlisted'],"category-filter":1},on:{"update":_vm.switchAdById}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('rules-manager-modal',{attrs:{"show":_vm.showRulesManager,"id":_vm.id,"calendarRules":_vm.calendarRules},on:{"rulesChanged":_vm.rulesChanged,"close":function($event){_vm.showRulesManager = false}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cursor-pointer text-capitalize rounded-xl mr-2",class:{'px-2 py-2' : _vm.$vuetify.breakpoint.mdAndDown},attrs:{"min-width":0,"elevation":"0","color":"white"},on:{"click":function($event){$event.preventDefault();_vm.showRulesManager = true}}},'v-btn',attrs,false), _vm.$vuetify.breakpoint.mdAndDown && on),[_c('v-icon',[_vm._v(" mdi-note-edit-outline ")]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('span',{staticClass:"font-weight-bold text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$t('Rule manager'))+" ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Rule manager')))])]),_c('v-menu',{attrs:{"attach":"","bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cursor-pointer text-capitalize rounded-xl",class:{'px-2 py-2' : _vm.$vuetify.breakpoint.mdAndDown},attrs:{"min-width":0,"elevation":"0","color":"black","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cog-outline ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"font-weight-medium text-decoration-none",attrs:{"link":"","dense":"","to":{ name: 'AdForm', params: {
                  id: this.$route.params.id,
                  categoryConst: this.$route.params.categoryConst,
                  stepSlug: 'pricing',
                }}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-cash")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Form Pricing Price Title'))+" ")])],1),_c('v-list-item',{staticClass:"font-weight-medium text-decoration-none",attrs:{"link":"","dense":"","to":{ name: 'AdForm', params: {
                  id: this.$route.params.id,
                  categoryConst: this.$route.params.categoryConst,
                  stepSlug: 'reservation',
                }}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-calendar-check")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Form Reservation Title'))+" ")])],1),_c('v-list-item',{staticClass:"font-weight-medium text-decoration-none",attrs:{"link":"","dense":"","to":{ name: 'AdForm', params: {
                  id: this.$route.params.id,
                  categoryConst: this.$route.params.categoryConst,
                  stepSlug: 'activity',
                }}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-timeline-clock-outline")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Activity Log'))+" ")])],1),(_vm.apartment && _vm.apartment.icals && _vm.apartment.icals.length)?_c('v-list-item',{staticClass:"font-weight-medium text-decoration-none",attrs:{"link":"","dense":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshIcal($event)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Sync Calendar'))+" ")])],1):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"font-weight-medium text-body-2 day-name-short",staticStyle:{"display":"flex","justify-content":"space-evenly"}},_vm._l((_vm.dayNames),function(dayName){return _c('div',{key:dayName,staticClass:"px-2 text-center text-truncate font-weight-light",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t(dayName))+" ")])}),0)]),_c('div',{class:{ 'non-admin-styles': !_vm.isAdmin },style:(_vm.$vuetify.breakpoint.lgAndUp ? 'margin-right:370px;' : ''),attrs:{"id":"calendar"},on:{"mouseup":_vm.mouseupGlobal}})],1),_c('calendar-sidebar',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndDown ? _vm.showMenu : true),expression:"$vuetify.breakpoint.mdAndDown ? showMenu : true"}],attrs:{"id":_vm.id,"start":_vm.start,"end":_vm.end,"events":_vm.events,"selectedEvent":_vm.selectedEvent,"specialPricing":_vm.specialPricing,"showMenu":this.showMenu,"calendarRules":_vm.calendarRules,"calendarRulesDates":_vm.calendarRulesDates},on:{"refetchEvents":_vm.refetchEvents,"clearSelectedDates":_vm.clearSelectedDates,"closeMenu":_vm.closeMenu,"priceChanged":_vm.priceChanged,"updateStartEnd":_vm.updateStartEnd,"rulesChanged":_vm.rulesChanged,"loadReservationInSidebar":_vm.loadReservationInSidebar,"removeReservationFromSidebar":_vm.removeReservationFromSidebar}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }