<template>
    <modal
      :show="show"
      :title="$t('Create new rule')"
      :sticky-header="true"
      :max-width="1000"
      @save="save"
      @close="close"
    >
    <v-container>
      <v-row>
        <v-col cols="12" md="8" >
          <rule-form
            :id="id"
            :start="start"
            :end="end"
            ref="form"
            @close="close"
            @rulesChanged="$emit('rulesChanged')"
          />
       </v-col>
          <v-col cols="12" md="4">
            <info
              title="Infos.CalendarRules.title"
              description="Infos.CalendarRules.description"
              icon="green-energy"
            />
          </v-col>
        </v-row>
      </v-container>
    </modal>
</template>
<script>
import RuleForm from '@/components/calendar/RuleForm.vue';
import Modal from '@/components/Modal.vue';
import Info from '@/components/Info.vue';

export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
  },
  components: {
    Modal,
    Info,
    RuleForm,
  },
  methods: {
    save() {
      this.$refs.form.save();
    },
    close() {
      this.$refs.form.clearForm();
      // console.log('close in rulesModal.vue');
      this.$emit('close');
    },
  },

};
</script>
