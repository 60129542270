<template>
  <v-container class="mt-0 mt-md-16 pt-8 pt-md-16 px-3 px-sm-12 px-lg-16">
    <v-row>
      <v-col md="8" xl="6" class="px-0">
        <v-list class="pt-0">
          <v-list-item class="mb-0 mb-md-6 ">
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold mb-4">
                {{$t('verify_listing_title')}}
              </v-list-item-title>
              <v-list-item-subtitle class="text-body-1 font-weight-medium text-wrap">
                {{$t('verify_listing_desc')}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item class="d-md-none mb-4">
            <v-card
              class="d-flex py-4 px-4 rounded-lg"
              width="100%"
              outlined
            >
              <v-img
                src="https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YXBhcnRtZW50fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                max-width="3.2rem"
                height="3.2rem"
                class="rounded-lg"
              ></v-img>
              <div class="ml-4 d-flex flex-column justify-center">
                <span class="text-body-2 font-weight-bold">moj apartman</span>
                <p class="text-body-2 mb-0">Bulevar kralja Aleksandra, Beograd, Serbia</p>
              </div>
            </v-card>
          </v-list-item> -->

          <v-list-item class=" section section-completed">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-subtitle-1 mb-1 text-decoration-none">
                  {{$t('verify_listing_create_title')}}
                </v-list-item-title>
                <!-- <v-list-item-subtitle class="text-body-1 mb-2 text-wrap">
                  {{$t('confirm_id_desc')}}
                </v-list-item-subtitle> -->
                <div class="d-flex align-center">
                  <v-icon color="green">mdi-check-circle</v-icon>
                  <p class="font-weight-black text-body-2 mb-0 ml-2">{{$t('complete')}}</p>
                </div>
              </v-list-item-content>
          </v-list-item>

          <v-list-item class="section d-flex" :class="missingEmailVerification ? '': 'section-completed'" :to="missingEmailVerification ? {name: 'user-settings.account'} : ''">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-subtitle-1 mb-1 text-decoration-none">
                  {{ $t( missingEmailVerification
                    ? 'confirm_email_verification'
                    : 'confirm_email_verification_completed')}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-1 mb-2 text-wrap" v-if="missingEmailVerification">
                  {{$t('confirm_email_verification_desc')}}
                </v-list-item-subtitle>
                <p v-if="missingEmailVerification" class="font-weight-black text-body-2 mb-0">{{$t('required')}}</p>
                <div v-else class="d-flex align-center">
                  <v-icon color="green">mdi-check-circle</v-icon>
                  <p class="font-weight-black text-body-2 mb-0 ml-2">{{$t('complete')}}</p>
                </div>
              </v-list-item-content>
              <v-btn icon class="mt-2 arrow-right">
                <v-icon large>mdi-chevron-right</v-icon>
              </v-btn>
          </v-list-item>

          <v-list-item class="section d-flex" :class="missingGovermentId ? '': 'section-completed'" :to="missingGovermentId ? {name: 'GovernmentId'} : ''">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-subtitle-1 mb-1 text-decoration-none">
                  {{ $t( missingGovermentId
                    ? 'confirm_id_image'
                    : 'confirm_id_image_completed')}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-1 mb-2 text-wrap" v-if="missingGovermentId">
                  {{$t('confirm_id_desc')}}
                </v-list-item-subtitle>
                <p v-if="missingGovermentId" class="font-weight-black text-body-2 mb-0">{{$t('required')}}</p>
                <div v-else class="d-flex align-center">
                  <v-icon color="green">mdi-check-circle</v-icon>
                  <p class="font-weight-black text-body-2 mb-0 ml-2">{{$t('complete')}}</p>
                </div>
              </v-list-item-content>
              <v-btn icon class="mt-2 arrow-right">
                <v-icon large>mdi-chevron-right</v-icon>
              </v-btn>
          </v-list-item>
          <v-list-item
            class="section d-flex"
            :class="missingPayoutsOrUserDetails ? '': 'section-completed'"
            :to="missingPayoutsOrUserDetails ? {name: 'user-settings.payouts'} : ''">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-subtitle-1 mb-1 text-decoration-none">
                  {{ $t( missingPayoutsOrUserDetails
                    ? 'confirm_account_details_and_user_payouts'
                    : 'confirm_account_details_and_user_payouts_completed')}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-1 mb-2 text-wrap" v-if="missingPayoutsOrUserDetails">
                  {{$t('confirm_account_details_and_user_payouts_desc')}}
                </v-list-item-subtitle>
                <p v-if="missingPayoutsOrUserDetails" class="font-weight-black text-body-2 mb-0">{{$t('required')}}</p>
                <div v-else class="d-flex align-center">
                  <v-icon color="green">mdi-check-circle</v-icon>
                  <p class="font-weight-black text-body-2 mb-0 ml-2">{{$t('complete')}}</p>
                </div>
              </v-list-item-content>
              <v-btn icon class="mt-2 arrow-right">
                <v-icon large>mdi-chevron-right</v-icon>
              </v-btn>
          </v-list-item>
          <!-- <v-list-item class="section" :to="{name: 'AdsCreate'}">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-subtitle-1 mb-1 text-decoration-none">
                  {{$t('confirm_number_title')}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-1 mb-2 text-wrap">
                  {{$t('confirm_number_desc')}}
                </v-list-item-subtitle>
                <p class="font-weight-black text-body-2 mb-0">{{$t('required')}}</p>
              </v-list-item-content>
              <v-btn icon class="mt-2">
                <v-icon large>mdi-chevron-right</v-icon>
              </v-btn>
          </v-list-item> -->
          <!-- <div>
            missingEmailVerification: {{missingEmailVerification}} <br/>
            missingGovermentId: {{missingGovermentId}} <br/>
            missingPayoutsOrUserDetails: {{missingPayoutsOrUserDetails}} <br/>
            missingPayouts: {{missingPayouts}} <br/>
            missingUserDetails: {{missingUserDetails}} <br/>
          </div> -->
        </v-list>
      </v-col>
      <!-- <v-col md="4" offset-xl="1" class="d-none d-md-block">
        <v-card
          class=" rounded-xl"
          max-width="20rem"
          outlined
        >
          <v-img
            src="https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YXBhcnRtZW50fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
          ></v-img>
          <div>
            <v-card-title class="text-body-1 font-weight-bold">
              {{'moj apartman'}}
            </v-card-title>
            <v-card-subtitle>
              {{'Bulevar Kralja Aleksandra, Beograd, Serbia'}}
            </v-card-subtitle>
          </div>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.section{
  border-bottom: 1px solid lightgray;
  padding: 1rem 0;
  margin: 0 1rem;
  align-items: baseline;
  display:flex;
}
.section:hover:not(.section-completed) {
  cursor: pointer;

  .text-subtitle-1 {
    text-decoration: underline !important;
  }
  .v-btn::before {
    opacity: 0.08;
  }
}
.section:last-child {
  border-bottom:none;
}
.section:hover::before{
  opacity: 0;
}
.section-completed .arrow-right{
  display:none;
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user']),
    missingEmailVerification() {
      return Boolean(this.user.email_verified_at === null);
    },
    missingGovermentId() {
      return !this.user.hasGovernmentId;
    },
    // verifyGovernmentId() {
    //   return Boolean(this.user.government_id_verified_at === null);
    // },
    missingPayouts() {
      return Boolean(this.user.payoutsCount === 0);
    },
    missingUserDetails() {
      return Boolean(this.user.hasUserDetails === false);
    },
    missingPayoutsOrUserDetails() {
      return this.missingPayouts || this.missingUserDetails;
    },
  },
};
</script>
