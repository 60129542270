<template>
  <modal
    :show="show"
    :title="$t('Rule manager')"
    title-class="justify-center"
    :max-width="1000"
    cssClass="rule-manager"
    @save="save"
    :save-text="saveText"
    @close="close"
  >
    <v-container fluid>
      <v-row
        :class="{'max-height-scroll': $vuetify.breakpoint.smAndDown}"
        class="height-100"
      >
        <v-col
          cols="12"
          md="3"
          class="border-right max-height-scroll"
        >
          <div v-if="calendarRules && calendarRules.length">
            <v-btn
              block
              outlined
              class="font-weight-bold"
              @click.stop="selectedRuleId = 'NEW'"
            >
              {{ $t('Create new rule') }}
            </v-btn>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <v-card
                v-for="rule in calendarRules"
                :key="rule.id" @click.stop="editRule(rule.id)"
                :class="{'active': selectedRuleId === rule.id}"
                outlined
                class="my-3 py-3 px-5 sidebar-rule"
                style="user-select: none;"
              >
                <div class="d-flex align-center">
                  <v-sheet
                    :color="rule.color"
                    height="10"
                    min-width="10"
                    class="mr-2"
                    style="border-radius: 9999px;"
                  ></v-sheet>
                  <v-card-subtitle
                    class="px-0 py-0 font-weight-bold black--text text-subtitle-1 text-truncate"
                  >
                    {{ rule.name }}
                  </v-card-subtitle>
                </div>
                <div v-if="rule.min_stay">{{ $t('Form Reservation MinStay') }}: {{ $tc('nights_count', rule.min_stay) }}</div>
                <div v-if="rule.price_adjustment">{{ $t('Price adjustment')}}: {{ rule.price_adjustment }}%</div>
              </v-card>
            </div>
            <div v-else>
              <div class="text-center mt-2 mb-2 text-uppercase">{{ $t('or') }}</div>
              <v-select
                :items="calendarRules"
                v-model="selectedRuleId"
                item-text="name"
                item-value="id"
                :placeholder="$t('Select rule to update')"
                outlined
                @change="editRule"
              />
            </div>
          </div>
          <div
            v-else
            class="d-flex flex-column align-center px-3 pt-2"
          >
            <v-img
              src="@/assets/rule-manager-empty.svg"
              :width="$vuetify.breakpoint.mdAndUp ? 100 : 70"
              class="mb-4"
            />
            <div>
              <v-list-item-title
                class="text-wrap font-weight-medium"
              >
                {{ $t('Rule Manager Empty Title') }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-wrap"
              >
                {{ $t('Rule Manager Empty Subtitle') }}
              </v-list-item-subtitle>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'max-height-scroll pb-10'"
        >
          <div v-if="selectedRuleId">

            <div v-if="selectedRuleId === 'NEW'">
              <rule-form
                :id="id"
                ref="form"
                @rulesChanged="newRuleCreated($event)"
                :key="`${selectedRuleId}_new`" >
              </rule-form>
            </div>
            <div v-else>
              <rule-form
                v-if="selectedRule"
                :id="id" ref="form"
                :rule="selectedRule"
                @rulesDeleted="rulesDeleted"
                @rulesChanged="$emit('rulesChanged')"
                :key="`${selectedRuleId}_update`"
              />
            </div>

          </div>
          <div v-else>
            <div
              v-if="calendarRules && calendarRules.length"
              class="d-flex flex-column-reverse flex-md-column align-center justify-center pt-16"
            >
              <v-img
                src="@/assets/edit-rule.svg"
                alt="Edit rules illustration image"
                class="mb-16"
                :width="$vuetify.breakpoint.mdAndUp ? 200 : 150"
              >
              </v-img>
              <div class="d-flex flex-column flex-md-row align-center justify-center mb-10 mb-md-0">
                <v-icon
                  x-large
                  color="black"
                  class="mr-md-10 mb-10 mb-md-0"
                >
                  {{ $vuetify.breakpoint.mdAndUp ? 'mdi-chevron-left' : 'mdi-chevron-up'}}
                </v-icon>
                <span class="text-h6 text-md-h5 font-weight-medium">
                  {{ $t('Select the rule you wish to edit') }}
                </span>
              </div>
            </div>
            <div v-else>
              <rule-form
                :id="id"
                ref="form"
                @rulesChanged="newRuleCreated($event)"
                :key="`${selectedRuleId}_no_rules`"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import FormValidationMixin from '@/lib/FormValidationMixin';
import Modal from '@/components/Modal.vue';
import RuleForm from '@/components/calendar/RuleForm.vue';

export default {
  mixins: [validationMixin, FormValidationMixin],
  props: {
    id: {
      required: true,
      type: String,
    },
    show: {
      required: true,
      type: Boolean,
    },
    calendarRules: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedRuleId: undefined,
      errors: [],
    };
  },
  components: {
    Modal,
    RuleForm,
  },
  computed: {
    ...mapGetters(['loading']),
    selectedRule() {
      return this.calendarRules.find((x) => x.id === this.selectedRuleId);
    },
    saveText() {
      if (this.selectedRuleId === 'NEW') {
        return 'Create';
      }
      if (this.selectedRuleId) {
        return 'Update';
      }
      return 'Create';
    },
  },
  methods: {
    close() {
      this.selectedRuleId = undefined;
      this.$emit('close');
    },
    save() {
      this.$refs.form.save('calendar/rules');
    },
    editRule(rule) {
      this.selectedRuleId = rule;
    },
    rulesDeleted() {
      this.selectedRuleId = undefined;
      this.$emit('rulesChanged');
    },
    newRuleCreated(ruleId) {
      this.selectedRuleId = ruleId;
      this.$emit('rulesChanged');
    },
  },

};
</script>
<style scoped lang="scss">
.rule-manager {
  .border-right {
    border-right: 1px solid #ccc;
  }
}
.active{
  outline: 1px solid black;
}
</style>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.v-dialog.rule-manager.v-dialog--active {
  min-height: 500px;
  height: 850px;
  overflow-y: hidden;

  .modal-header {
    border-bottom: 1px solid #ccc;
  }
  .max-height-scroll{
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .height-100{
    height: 100%;
  }
}
</style>
