<template>
<div>
    <v-card-title class="pt-0">{{ title }}</v-card-title>
    <v-card-subtitle>
      {{ this.rule && this.rule.id
        ? $t('Rule Form Edit Description')
        : $t('Rule Form Create New Description') }}
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <v-text-field
        v-model="form.name"
        :error-messages="firstError('form.name')"
        type="text"
        class="mt-2"
        :class="{'custom-input-width': $vuetify.breakpoint.mdAndUp}"
        outlined
        :label="$t('Rule name')"
        :placeholder="$t('Rule Form Name Hint')"
        min="1"
      />
    </v-card-text>

    <v-card-title class="pt-0">{{ $t('Rule Form Color Picker Title') }}</v-card-title>
    <v-card-subtitle>{{ $t('Rule Form Color Picker Description') }}</v-card-subtitle>
    <v-card-text class="d-flex align-center pb-0">
      <v-item-group
        mandatory
        v-model="form.color"
        class="d-flex flex-wrap"
      >
        <div
          class="mr-2 d-flex mb-2"
          v-for="color in colorOptions"
          :key="color"
        >
          <v-item
            v-slot="{ active, toggle }"
            :value="color"
          >
            <div
              @click="toggle"
              :style="{backgroundColor: color}"
              :class="{'active': active}"
              class="circle-color cursor-pointer mr-2"
            >
            </div>
          </v-item>
        </div>
      </v-item-group>
    </v-card-text>

    <v-card-title >
      <span class="mr-2">
        {{ $t('Price') }}
      </span>
    </v-card-title>
    <v-card-subtitle>
      {{ $t('Rule Form Price Description') }}
    </v-card-subtitle>
    <v-card-text class="d-flex my-0 py-0 pb-0">
        <div
          class="d-flex pt-1 custom-input-width"
        >
          <v-text-field
            v-model="form.price_adjustment"
            :error-messages="firstError('form.price_adjustment') || errors.price_adjustment"
            type="number"
            outlined
            prefix="%"
            min="1"
            max="500"
            clearable
          />
        </div>
        <div class="d-flex">
          <v-radio-group v-model="form.price_adjustment_direction" class="mt-0 pl-5 text-capitalize">
            <v-radio value="-" :label="$t('discount')"/>
            <v-radio value="+" :label="$t('increase')"/>
          </v-radio-group>
        </div>
    </v-card-text>
    <v-card-title class="pt-0">
      <span class="mr-2">
        {{ $t('Rule Form length Of Stay Title') }}
      </span>
    </v-card-title>
    <v-card-subtitle>
      {{ $t('Rule Form length Of Stay Description') }}
    </v-card-subtitle>
    <v-card-text class="">
      <v-text-field
        v-model="form.min_stay"
        :error-messages="firstError('form.min_stay') || errors.min_stay"
        type="number"
        outlined
        :suffix="$t('nights')"
        :label="$t('Form Reservation MinStay')"
        :placeholder="$t('Form Reservation MinStay')"
        :hint="`${$t('Form Reservation MinStay Hint Start')}
        ${form.min_stay ? form.min_stay : '__'} ${$t('Form Reservation MinStay Hint End')}`"
        persistent-hint
        min="1"
        :class="{'custom-input-width': $vuetify.breakpoint.mdAndUp}"
      />
  </v-card-text>
  <div v-if="rule && rule.id" class="mt-5 mb-10">
    <v-card-text class="pt-0">
        <v-btn
          color="red"
          class="white--text elevation-0"
          @click.stop="deletePreviewRule(rule.id)"
        >
        <v-icon
          class="cursor-pointer mr-2"
          dense
        >
          mdi-delete-outline
        </v-icon>
          {{ $t('Delete rule') }}
      </v-btn>
      </v-card-text>
  </div>
  <modal
    v-if="rule && rule.name && rule.id"
    :show="showDeleteConfirmModal"
    :title="$t('Confirm Delete')"
    :max-width="500"
    save-text="Delete"
    @save="deleteRule(rule.id)"
    @close="showDeleteConfirmModal = false"
  >
    <v-container>
      <v-row>
        <v-col class="max-height-scroll">
          <v-card-subtitle class="text-subtitle-1 black--text">
            {{ $t('Rule Delete Title') }} <b>{{ rule.name }}</b>?
          </v-card-subtitle>
          <v-card-subtitle
            v-if="periodsToDeletePreviewComputed.length"
            class="py-0 text-subtitle-1"
          >
            {{ $t('Rule Delete Description') }}
          </v-card-subtitle>
          <v-list
            class="pt-0 mb-8"
            max-height="400px"
            v-if="periodsToDeletePreviewComputed.length"
          >
            <v-list-item
              v-for="period in periodsToDeletePreviewComputed"
              :key="period.id"
              dense
              class="list-item-short"
            >
              <v-list-item-icon class="mr-1 ml-0 my-1">
                <v-icon large>
                  mdi-circle-small
                </v-icon>
              </v-list-item-icon>
              <span v-html="period"></span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </modal>
</div>
</template>
<style lang="scss" scoped>
.custom-input-width{
  max-width: 50%;
  width: 100%;
}
.circle-color{
  height: 27px;
  width: 27px;
  border-radius: 50%;
  transition: all .1s;
}
.circle-color.active{
  outline: 2px solid #989898;
  outline-offset: 3px;
}
.list-item-short{
  min-height: 0px !important;
  height: 30px;
}
</style>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import {
  required,
  requiredIf,
  maxValue,
  minValue,
} from 'vuelidate/lib/validators';
import {
  humanFriendlyRangeString,
} from '@/components/calendar/config';
import FormValidationMixin from '@/lib/FormValidationMixin';

export default {
  mixins: [validationMixin, FormValidationMixin],
  props: {
    id: {
      required: true,
      type: String,
    },
    start: {
      // required: true,
      type: String,
    },
    end: {
      // required: true,
      type: String,
    },
    rule: {
      // required: true,
      type: Object,
    },
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        price_adjustment: {
          // eslint-disable-next-line
          youMustSelectAtLeastOneRule: requiredIf(function () {
            return this.form.min_stay === null || this.form.min_stay === '';
          }),
          minValue: minValue(1),
          maxValue: maxValue(this.form.price_adjustment_direction === '+' ? 500 : 99),
        },
        min_stay: {
          // eslint-disable-next-line
          youMustSelectAtLeastOneRule: requiredIf(function () {
            return this.form.price_adjustment === null || this.form.price_adjustment === '';
          }),
          maxValue: maxValue(365),
          minValue: minValue(1),
        },
      },
    };
  },
  data() {
    return {
      form: {
        name: null,
        color: '#f44336',
        price_adjustment: null,
        price_adjustment_direction: '+',
        min_stay: null,
      },
      errors: [],
      periodsToDeletePreview: [],
      showDeleteConfirmModal: false,
      colorOptions: [
        '#DC2626',
        '#6F1AB6',
        '#FB2576',
        '#9D5C0D',
        '#5800FF',
        '#27AA80',
        '#FF6D28',
        '#3282B8',
      ],
    };
  },
  watch: {
    rule: {
      handler() {
        if (this.rule && this.rule.id && this.rule.name && (this.rule.min_stay || this.rule.price_adjustment)) {
          this.form.name = this.rule.name;
          if (this.rule.min_stay) {
            this.form.min_stay = this.rule.min_stay;
          }
          if (this.rule.price_adjustment) {
            if (this.rule.price_adjustment < 0) {
              this.form.price_adjustment_direction = '-';
            } else if (this.rule.price_adjustment > 0) {
              this.form.price_adjustment_direction = '+';
            }
            this.form.price_adjustment = Math.abs(this.rule.price_adjustment);
          }
          if (this.rule.color) {
            this.form.color = this.rule.color;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['loading']),
    periodsToDeletePreviewComputed() {
      const periods = [];
      if (this.periodsToDeletePreview.length) {
        this.periodsToDeletePreview.forEach((period) => {
          if (period.apartment && period.apartment.name) {
            periods.push(`<b>${period.apartment.name}</b>: ${humanFriendlyRangeString(period.start, period.end)}`);
          }
        });
      }
      return periods;
    },
    title() {
      if (this.rule && this.rule.id) {
        return this.$t('Edit rule');
      }
      return this.$t('Create new rule');
    },
    ruleId() {
      if (this.rule && this.rule.id && this.rule.name) {
        return this.rule.id;
      }
      return undefined;
    },
  },
  methods: {
    clearForm() {
      this.form.name = null;
      this.form.price_adjustment = null;
      this.form.price_adjustment_direction = '+';
      this.form.min_stay = null;
      this.form.color = '#f44336';
    },
    save(api = `calendar/rules/period/${this.id}`) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      const form = JSON.parse(JSON.stringify(this.form));
      if (form.price_adjustment_direction === '-') {
        form.price_adjustment *= -1;
      }
      form.start = this.start;
      form.end = this.end;
      if (this.rule && this.rule.id) {
        form.id = this.rule.id;
      }
      axios
        .post(api, {
          ...form,
        })
        .then(({ data }) => { // return created/updated ruleId
          this.$store.dispatch(
            'message',
            this.$t(
              this.ruleId
                ? 'Rule updated'
                : 'Rule saved',
              { name: this.form.name },
            ),
          );
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
          this.errors = [];
          this.$emit('rulesChanged', data);
          if (!this.ruleId) {
            this.$v.$reset();
            this.clearForm();

            if (this.start && this.end) {
              this.$emit('close');
            }
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    deletePreviewRule(ruleId) {
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/rules/delete-preview/${ruleId}`)
        .then(({ data }) => {
          this.$store.dispatch('loading', false);
          this.periodsToDeletePreview = data;
          this.showDeleteConfirmModal = true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
      // console.log(`delete rule ${ruleId}`);
    },
    deleteRule(ruleId) {
      axios
        .delete(`calendar/rules/${ruleId}`)
        .then(() => {
          this.$store.dispatch('loading', false);
          this.periodsToDeletePreview = [];
          this.showDeleteConfirmModal = false;
          this.$store.dispatch(
            'message',
            this.$t('Rule deleted'),
          );
          this.$emit('rulesDeleted');
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
  },

};
</script>
